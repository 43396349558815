import React, { useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { P } from './Text';
import { Link } from 'gatsby';

Tile.propTypes = {
    href: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    subtitle: PropTypes.string,
    textPosition: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.string,
};

Tile.defaultProps = {
    size: 'default',
    textPosition: 'top-left',
    theme: 'light'
};

function Tile(props) {
    const [mousePosition, setMousePosition] = useState([0, 0]);
    const [isHovered, setIsHovered] = useState(false);
    const containerElement = useRef(null);

    const handleMouseEnter = (evt) => {
        setIsHovered(true);
    }

    const handleMouseLeave = (evt) => {
        setIsHovered(false);
    }

    const handleMouseMove = (evt) => {
        const rect = evt.target.getBoundingClientRect();
        const x = evt.clientX - Math.round(rect.left);
        const y = evt.clientY - Math.round(rect.top);

        setMousePosition([x, y]);
    }

    const getBackgroundOffset = () => {
        if (!containerElement.current) return null;
        const [x, y] = mousePosition;
        const { width, height } = containerElement.current.getBoundingClientRect();
        const center = [Math.round(width / 2), Math.round(height / 2)];
        const buffer = 8;
        const bgX = Math.round((x - center[0]) / buffer);
        const bgY = Math.round((y - center[1]) / buffer);

        return [bgX, bgY];
    }

    const bgOffset = getBackgroundOffset();
    const springProps = useSpring({
        transform: isHovered ? `scale(1.2) translate(calc(-50% - ${bgOffset[0]}px), calc(-50% - ${bgOffset[1]}px))` : 'scale(1) translate(calc(-50% - 0px), calc(-50% - 0px))',
    });

    return (
        <Container
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            ref={containerElement}
            size={props.size}
        >
            <Img
                style={springProps}
                src={props.src}
            />
            <Info
                position={props.textPosition}
                size={props.size}
                theme={props.theme}
            >
                <Title>{props.title}</Title>
                <Subtitle>{props.subtitle}</Subtitle>
            </Info>
            {props.href ? (
                <StyledLink to={props.href} />
            ) : null}
        </Container>
    );
}

const Container = styled.div`
    position: relative;
    height: 400px;
    margin-right: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    ${props => props.size === 'small' ? 'width: 400px;' : null}
    ${props => props.size === 'large' ? 'width: 100%;' : null}
    ${props => props.size === 'default' ? 'flex-grow: 1;' : null}

    @media (max-width: 1000px) {
        width: 100% !important;
    }
`;

const Img = styled(animated.img)`
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    transform-origin: 0 0;
`;

const Info = styled.div`
    position: absolute;
    width: ${props => props.size === 'large' ? '280px' : '250px' };
    top: ${props => props.position === 'top-left' || props.position === 'top-right' ? '28px' : 'auto'};
    bottom: ${props => props.position === 'bottom-left' || props.position === 'bottom-right' ? '28px' : 'auto'};
    left: ${props => props.position === 'top-left' || props.position === 'bottom-left' ? '28px' : 'auto'};
    right: ${props => props.position === 'top-right' || props.position === 'bottom-right' ? '28px' : 'auto'};
    text-align: ${props => props.position.includes('-right') ? 'right' : 'left'};
    ${props => props.theme === 'dark' ? 'color: black;' : null}
`;

const Title = styled.h3`
    font-size: 18px;
    margin-bottom: 8px;
`;

const Subtitle = styled(P)`
    font-size: 14px;
`;

const StyledLink = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export default Tile;