import React from "react";
import styled from 'styled-components';

function Grid({ children }) {
    return (
        <Container>
            {children}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    margin-right: -8px;
    margin-bottom: -8px;
    flex-wrap: wrap;
`;

export default Grid;

