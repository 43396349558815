import React from "react";
import Text from '@components/Text';
import Blurb from '@components/Blurb';
import Transition from '@components/Transition';
import websiteArt from '@assets/img/website-art.jpg';
import yvTile from '@assets/img/youth-venture.jpg';
import gatkuTile from '@assets/img/gatku-tile.jpg';
import rollerBladeTile from '@assets/img/rollerblade-tile.jpg';
import cityDefenseTile from '@assets/img/city-defense-tile.jpg';
import slackTile from '@assets/img/slack-art-3.png';
import Tile from "@components/Tile";
import Grid from '@components/Grid';
import Link from '@components/Link';
import Title from '@components/Title';
import { Helmet } from "react-helmet"

export default () => (
    <Transition>
        <Helmet>
            <title>Austen Payan | User Interface Engineer</title>
        </Helmet>
        <Title>User Interface Engineer</Title>
        <Blurb
            left={() => (
                <Text>New York City</Text>
            )}
            right={() => (
                <Text>I build web applications that are fast, beautiful, and organized. With an emphasis on clear communication and sweating the details, I've had the privilege of playing key roles on great engineering teams that ship great products. I'm currently a Senior Frontend Engineer at Slack. <Link href="mailto:austenpayan@gmail.com" isUnderlined>Drop me a line</Link>, or say hello on <Link href="https://twitter.com/a__pay" isUnderlined>Twitter</Link> <span role="img" aria-label="waving hand">👋</span>.</Text>
            )}
        />
        <Grid>

            <Tile
                href="/work/zingle"
                src={websiteArt}
                subtitle="A communication platform that helps businesses engage with their customers right from their devices."
                textPosition="top-left"
                title="Zingle"
            />
             <Tile
                href="/work/slack"
                size="small"
                src={slackTile}
                subtitle="Improving search in the most popular communication app for work"
                textPosition="bottom-left"
                title="Slack"
            />
            {/* <Tile
                href="/work/visit"
                size="small"
                src={yvTile}
                subtitle="A check-in system for Youth Venture Teen Centers"
                textPosition="bottom-left"
                title="Visit"
            /> */}
            <Tile
                href="/work/gatku"
                size="large"
                src={gatkuTile}
                subtitle="A custom eCommerce and CMS solution for the world's hottest polespear brand."
                textPosition="top-right"
                theme="dark"
                title="GATKU Polespears"
            />
            <Tile
                href="/work/rollerblade"
                size="small"
                src={rollerBladeTile}
                subtitle="A 360° image rotation plugin for jQuery with over 500 stars on Github."
                textPosition="top-left"
                title="Rollerblade.js"
            />
            <Tile
                href="/work/city-defense"
                src={cityDefenseTile}
                subtitle="A browser game built for fun to try out new ES2015 features."
                textPosition="bottom-right"
                title="City Defense"
            />
        </Grid>
        <Blurb
            useTopRule
            right={() => (
                <Text>I have been a part of web projects of all sizes, spanning all types of responsibilities. With experience in design, front & back-end development, and database management, I offer a broad skillset complimentary to any team and any sized project.</Text>
            )}
        />
    </Transition>
);

